$task-font-family: inherit !default;

$tile-background-color: white !default;
$tile-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2) !default;

$task-background-color: white !default;

$promotion-content-width: 354px !default;

$task-link-button-color: #bfbfbf !default;
$task-link-button-font-family: $task-font-family !default;
$task-link-button-font-size: 18px !default;
$task-link-button-font-weight: bold !default;
$task-link-button-padding: 0px 15px 0px 0px !default;

$promotion-media-aspect-ratio: percentage(9/16) !default; //9:16 = percentage(9/16);
$promotion-media-transition-duration: 300ms !default;
$promotion-media-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.tile {
  background-color: $tile-background-color;
  box-shadow: $tile-box-shadow;

  .promotion {
    position: relative;
    display: flex;
    flex-direction: column;

    .flag {
      display: none;
    }

    & > .media {
      margin: 0px;

      & > div {
        position: relative;
        width: 100%;
        // padding-bottom: $promotion-media-aspect-ratio; // aspect ratio
        overflow: hidden;

        // & > div {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        // }

        img,
        video {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }

        .video-container {
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;
          display: flex;
          background-color: black;
        }
      }

      .media-animate-enter {
        opacity: 0;
        transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
        position: relative;
      }

      .media-animate-enter.media-animate-enter-active {
        opacity: 1;
        position: relative;
      }

      .media-animate-exit {
        opacity: 1;
        transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
        position: relative;

        & > * {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
        }
      }

      .media-animate-exit.media-animate-exit-active {
        opacity: 0;
        position: relative;
      }
    }

    & > .content {
      position: relative;
      width: 100%;
      background-color: $task-background-color;
      box-sizing: border-box;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 25px;
        top: -12px;
        width: 0px;
        height: 0px;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 13px solid $task-background-color;
      }
    }

    & > .overlay-container {
      .overlay {
        position: absolute;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .tile:not(.reverse) {
    &.large {
      .promotion {
        display: flex;
        // DO NOT NEED THIS FOR CENTENE SKIN
        // flex-direction: row;
        justify-content: flex-start;

        & > .media {
          // DO NOT NEED THIS FOR CENTENE SKIN
          // width: calc(100% - #{$promotion-content-width});
          order: 0;
        }

        & > .content {
          display: flex;
          // width: $promotion-content-width;
          flex-shrink: 0;
          order: 1;
        }

        & > .overlay-container {
          .overlay {
            position: absolute;
            left: auto;
            right: 0px;
            // DO NOT NEED IT IN DESKTOP VIEW AS WELL
            // width: $promotion-content-width;
            top: 0px;
            bottom: 0px;
          }
        }
      }
    }
  }

  .tile.reverse {
    &.large {
      .promotion {
        display: flex;
        // flex-direction: row;

        & > .media {
          // width: calc(100% - #{$promotion-content-width});
          width: 100%;
          // order: 1; // causing tile content to disappear in firefox
        }

        & > .content {
          display: flex;
          // width: $promotion-content-width;
          flex-shrink: 0;
          order: 0;
        }

        & > .overlay-container {
          .overlay {
            position: absolute;
            left: 0px;
            right: auto;
            width: $promotion-content-width;
            top: 0px;
            bottom: 0px;
          }
        }
      }
    }
  }
}
