//*******************************************************************************************/
//*************** pages/main.scss - the starting file for global-defaults *******************/
//*******************************************************************************************/

.main-page {
  // With the potential for modules (eg. Merchandise Marketplace Wishlist), use flexbox to even out tile height
  .ragged-layout {
    .item-container {
      display: flex;
      flex-wrap: wrap;

      .tile.small {
        > div {
          height: 100%;
        }

        .board-container-main {
          display: flex;
          height: 100%;

          .promotion.single_block {
            display: flex;
            width: 100%;

            .media {
              height: 100%;

              > div {
                display: flex;
                height: 100%;

                .block {
                  height: 100%;

                  .block-content {
                    display: flex;
                    flex-direction: column;

                    img {
                      height: auto;
                    }

                    .text {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      height: 100%;
                      align-items: flex-start;
                    }

                    .category-time-container {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.take-quiz {
  .secondary-btn {
    background-color: $white;
    border: none;
  }
}
