$label-color: black !default;
$label-font-family: inherit !default;
$label-font-weight: normal !default;
$label-font-size: 18px !default;
$label-line-height: inherit !default;
$label-margin: 0px !default;

$input-font-family: inherit !default;
$input-font-weight: normal !default;
$input-font-size: 20px !default;
$input-line-height: inherit !default;
$input-margin: 5px 0px 0px 0px !default;

/**********************   */

$survey-question-margin: 0px 0px 20px 0px !default;

$survey-question-label-color: $label-color !default;
$survey-question-label-font-family: $label-font-family !default;
$survey-question-label-font-weight: $label-font-weight !default;
$survey-question-label-font-size: $label-font-size !default;
$survey-question-label-line-height: $label-line-height !default;
$survey-question-label-margin: 0px 0px 5px 0px !default;
$survey-question-label-width: 100%;

$survey-answer-label-color: $label-color !default;
$survey-answer-label-font-family: $label-font-family !default;
$survey-answer-label-font-weight: $label-font-weight !default;
$survey-answer-label-font-size: $label-font-size !default;
$survey-answer-label-line-height: $label-line-height !default;
$survey-answer-label-margin: 10px 0px 0px 0px !default;

$survey-answer-input-font-family: $input-font-family !default;
$survey-answer-input-font-weight: $input-font-weight !default;
$survey-answer-input-font-size: $input-font-size !default;
$survey-answer-input-line-height: $input-line-height !default;
$survey-answer-input-margin: 0px 0px 0px 0px !default;

$survey-question-message-color: red !default;
$survey-question-message-font-family: inherit !default;
$survey-question-message-font-weight: normal !default;
$survey-question-message-font-size: 16px !default;
$survey-question-message-line-height: inherit !default;
$survey-question-message-margin: 10px 0px 0px 0px !default;

.task-container {
  .error-carousel.survey,
  .error-carousel.multipage-survey {
    display: none;
  }
  .confirmation-popup {
    //text-align: center;
  }
  .question_title {
    font-weight: bold;
  }
}

.multipage-survey {
  .survey-question-container {
    text-align: left;
    padding: 0;
    section {
      background: #f6f6f6;
      padding: 3rem 1.25rem 2.5rem 1.25rem;
      h1 {
        margin: 0;

        @media screen and (min-width: 768px) {
          font-size: 26px;
        }
      }
    }

    .completion-progress {
      display: flex;
      justify-content: flex-end;
      color: #6c6c6c;
      align-items: center;
      font-size: 21px;
      margin-bottom: 0.25rem;

      .completed {
        color: #cb117d;
      }

      .completed,
      .total {
        font-size: 21px;
      }
    }

    .react-sweet-progress-line {
      background-color: #ccc;
    }

    .react-sweet-progress-line-inner-status-active:before {
      animation: none;
    }

    .react-sweet-progress-symbol {
      display: none;
    }

    .survey-nav-buttons {
      display: flex;
      padding-bottom: 1rem;
      @media screen and (min-width: 1024px) {
        bottom: 30%;
        left: 2%;
      }

      button {
        margin: 0.25rem;

        @media screen and (min-width: 768px) {
          width: 150px;
        }
      }
    }

    form {
      padding: 3.25rem 1.25rem 0 1.25rem;
      .question-container {
        .question {
          font-weight: normal;
          color: #cb117d;
          font-size: 21px;
          margin-bottom: 1rem;
        }

        @media screen and (min-width: 1024px) {
          width: 40%;
        }
      }
    }
  }

  .modal-container .modal-overlay .modal .content-container .content {
    padding: 0;
  }
}

.survey-question-container {
  .question-container {
    margin: $survey-question-margin;

    & > fieldset {
      border: none;
      padding: 0px;
      margin: 0px;
    }

    .question {
      display: block;
      color: $survey-question-label-color;
      font-family: $survey-question-label-font-family;
      font-weight: $survey-question-label-font-weight;
      font-size: $survey-question-label-font-size;
      line-height: $survey-question-label-line-height;
      margin: $survey-question-label-margin;
      width: $survey-question-label-width;
    }

    .form-group {
      margin: 0px;

      label,
      legend {
        color: $survey-answer-label-color;
        font-family: $survey-answer-label-font-family;
        font-weight: $survey-answer-label-font-weight;
        font-size: $survey-answer-label-font-size;
        line-height: $survey-answer-label-line-height;
        margin: $survey-answer-label-margin;
      }

      label.checkbox,
      label.radio {
        font-family: $survey-answer-input-font-family;
        font-weight: $survey-answer-input-font-weight;
        font-size: $survey-answer-input-font-size;
        line-height: $survey-answer-input-line-height;
        margin: $survey-answer-input-margin;
      }

      select {
        margin: $survey-answer-input-margin;
      }
    }

    .message {
      color: $survey-question-message-color;
      font-family: $survey-question-message-font-family;
      font-weight: $survey-question-message-font-weight;
      font-size: $survey-question-message-font-size;
      line-height: $survey-question-message-line-height;
      margin: $survey-question-message-margin;
    }

    div.answer {
      display: inline;
    }
  }
  p.complete {
    span {
      color: #000;
    }
    span:first-child {
      color: #000;
    }
  }
  .content-body {
    .accordion .panel {
      .panel-heading {
        .panel-toggle {
          display: none;
        }
        .panel-title {
          padding: 0;
        }
        .nonstandard {
          display: inline-block !important;
          background-color: #fff;
          color: #000;
          &:focus {
            outline: none;
          }
          &:hover {
            border: none;
          }
        }
        .img-question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .separator {
            display: flex;
            align-items: center;
          }
          // used in select-promotion
          .caret {
            width: 20px;
            height: 20px;
          }
          .img-container {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 7px;
            img {
              width: 30px;
              height: auto;
            }
          }
        }
      }
      .panel-body {
        article .question-container.radio legend.question {
          display: none;
        }
      }
      // used in select-promotion
      &.expanded {
        .panel-heading {
          .caret {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .confirmation-popup {
    .img-container {
      width: 175px;
      height: 175px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.25rem;
      img {
        width: 140px;
        height: auto;
      }
    }
  }
}
