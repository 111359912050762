.merchandise-marketplace-tile {
  padding: 1rem 0;

  .common-single-block {
    border-radius: 8px;
    padding: 0 1rem 1rem 1rem;
    background-color: #fff;
    height: 100%;

    // When displayed in carousel, tiles are stacked and flexbox cancelled, so manually adjust height to match small promotion tiles
    .carousel & {
      min-height: 243px;
    }
  }

  .merchandise-marketplace-tile-content {
    display: flex;
    flex-direction: column;

    .merchandise-marketplace-tile-title {
      position: absolute;
      top: 1rem;
      left: 1rem;
      font: normal normal normal 14px/20px adelle-sans;
      letter-spacing: 0;
      height: 21px;
      text-align: left;
      color: #101010;
    }

    .merchandise-marketplace-tile-error {
      padding: 3rem 0 1rem 0;
      font-size: 20px;
      flex-grow: 1;
    }

    .button {
      margin: 0;
      display: block;
    }

    .merchandise-carousel {
      .merchandise-carousel-item {
        .details {
          .details-title {
            -webkit-line-clamp: 5;
          }
        }

        img, .placeholder-content-image {
          width: 136px;
          height: 136px;
        }
      }
    }
  }
}
