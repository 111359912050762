@import '../abstracts/variables';
@import '../../../app/q360-comps/biw-modal/BIWModal';
@import '../../../app/q360-comps/placeholder-content/src/placeholder-content.scss';

html {
  margin: 0;
  padding: 0;
  height: 100%;  box-sizing: border-box;
}

// adjust box-sizing for every element ever
*,
*::before,
*::after {
  box-sizing: inherit;

}

body {
  transition: opacity 0.3s;
  height: 100%;
  margin: 0;
  background-color: $body-background-color;
  font-family: $branded-font-stack;
  overflow-y: scroll;
  color: $global-default-font-color;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Remove borders around images in anchor tags in IE10 */
a img {
  border: none;
}

a {
  color: inherit;
}

#root {
  /* stylelint-disable-line selector-max-id */
  height: 100%;
}

.site {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > * {
    flex-shrink: 0;
  }

  &.disable-scroll {
    overflow: hidden;
  }

  .admin-bar {
    display: none;
    position: fixed;
    box-sizing: border-box;
    color: white;
    background-color: #f57301;
    font-weight: bold;
    text-align: center;
    line-height: $admin-bar-height;
    width: 100%;
    height: $admin-bar-height;
    padding: 0px 10px;
    z-index: 999999;
    overflow: hidden;
  }

  &.admin-user {
    .admin-bar {
      display: block;
    }
  }
}

.text-center {
  text-align: center;
}

.page {
  transition: $transition-global;
  // removed after bad merge in "page content stacking order" 0cbccb3
  // z-index: 30;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: $header-height-mobile;

    .site.admin-user & {
      margin-top: ($header-height-mobile + $admin-bar-height);
    }

    .site.language & {
      margin-top: ($header-height-mobile + $language-bar-height);
    }

    .site.admin-user.language & {
      margin-top: ($header-height-mobile + $admin-bar-height + $language-bar-height);
    }

    .footer::after {
      content: '\00a0'; /* &nbsp; */
      display: block;
      margin-bottom: $dock-height-mobile;
      height: 0px;
      visibility: hidden;
    }

    &.menu {
      .page {
        display: none;
      }

      .footer {
        display: none;
      }
    }
  }
  @media screen and (min-width: 768px) {
    margin-top: $header-height;

    .site.admin-user & {
      margin-top: ($header-height + $admin-bar-height);
    }

    .site.language & {
      margin-top: ($header-height + $language-bar-height);
    }

    .site.admin-user.language & {
      margin-top: ($header-height + $admin-bar-height + $language-bar-height);
    }

    .footer::after {
      content: '\00a0'; /* &nbsp; */
      display: block;
      margin-bottom: $dock-height-mobile; // should be $dock-height but that is not yet defined
      height: 0px;
      visibility: hidden;
    }

    &.menu {
      .page {
        display: none;
      }

      .footer {
        display: none;
      }
    }
  }

  .submitting {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    color: black;
    background: rgba(255, 255, 255, 0.5);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 300ms linear opacity;

    &.active {
      opacity: 1;
    }
  }
}

.site-content {
  padding: $site-content-vert-padding 0;
  width: 100%;
  box-sizing: border-box;
  transition: all 500ms;

  & > .content-wrapper {
    // main wrapper of content
    background-color: $body-background-color;
    & > .content {
      padding: 2.5rem;
      &.register {
        padding: 0;
        max-width: 1280px;
      }
      .content-description {
        margin-bottom: 1rem;
      }
    }
  }
}

.site-content::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: $site-content-vert-padding;
  height: 0px;
  visibility: hidden;

  @media screen and (max-width: 1023px) {
    margin-top: 0px;
  }
}

$content-wrapper-max-width: 1280px; // could be 1280px
.content-wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  flex-grow: 1;
  .content {
    // Didn't realize this to change misc pages, so remove it for now
    // and explicitely adding max-width to specific pages
    // max-width: $content-wrapper-max-width;
    margin: 0 auto;
  }
}

.modal-container {
  .modal-overlay {
    @media screen and (max-width: 1023px) {
      margin-top: $header-height-mobile;

      .site.admin-user & {
        margin-top: ($header-height-mobile + $admin-bar-height);
      }

      .site.language & {
        margin-top: ($header-height-mobile + $language-bar-height);
      }

      .site.admin-user.language & {
        margin-top: ($header-height-mobile + $admin-bar-height + $language-bar-height);
      }
    }
  }
}

.page-transition-enter {
  z-index: 10;
  opacity: 0;
  transform: translateY(100%) scale(0);
}

.page-transition-enter.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0%) scale(1);
}

.page-transition-exit {
  opacity: 1;
}

.page-transition-exit.page-transition-exit-active {
  opacity: 0;
}
.menu-animate-enter {
  opacity: 0;
}

.menu-animate-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.menu-animate-exit {
  opacity: 1;
}

.menu-animate-exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.language-transition-move {
  transition: all 500ms;
}

.header-transition-move {
  transition: all 500ms;
}

.footer-transition-move {
  transition: all 500ms;
}

.content-header {
  margin: 0;
  font-size: 2.125rem;
  font-weight: normal;
  color: #cb117d;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
    font-size: 32px;
    font-weight: normal;
    line-height: 1.2;
  }
}

.content-description {
  margin: 0px;
  font-size: 15px;
  font-weight: normal;
  color: #000;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
    font-size: 15px;
    font-weight: normal;
  }
}

.content-body {
  // background: $very-light-grey; // set on body
  margin: 0px 0px 0px 0px;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ReactTable {
  .-loading {
    opacity: initial !important;
    display: none !important;

    &.-active {
      display: block !important;
    }
  }
}

.page-margin {
  // padding: 16px 41px 0px 41px;
  margin: 0;
}

// Loading Spinner
.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms linear opacity;
  background: rgba(255, 255, 255, 0.6);
  flex-direction: column;

  &.active {
    opacity: 1;
    z-index: 50;
  }

  .activity-indicator {
    position: relative;
    margin: 0px auto;
    width: 90px;
    height: 90px;

    .circular-loader {
      animation: rotate-loader 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }

    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      animation: dash-loader 1.5s ease-in-out infinite, color-loader 6s ease-in-out infinite;
      //stroke-linecap: round;
    }

    @keyframes rotate-loader {
      0% {
        transform: none;
      }
      50% {
        // !important here generates a css warning in Firefox,
        // but if you remove it, the chrome-like loading spinner
        // to go between pages will become all jerky!
        transform: none !important;
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash-loader {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }

    @keyframes color-loader {
      0% {
        stroke: $brand-primary-color;
      }
      40% {
        stroke: $brand-primary-color;
      }
      66% {
        stroke: $brand-primary-color;
      }
      80%,
      90% {
        stroke: $brand-primary-color;
      }
    }
  }
  .loading:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
