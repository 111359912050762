.site-content > .content-wrapper > .content {
  &.tsys-activity {
    padding: 0;
    margin: 0 auto;

    .content-header {
      font-size: 21px;
      padding: 1.25rem 2rem;
      max-width: 1280px;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        font-size: 26px;
        padding-top: 26px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 42px;
        padding-top: 42px;
        width: 90%;
      }
    }

    .content-description {
      padding: 0 2rem 2rem 2rem;
      max-width: 1280px;
      margin: 0 auto;
      a {
        color: #006dc1;
      }

      @media screen and (min-width: 1024px) {
        width: 90%;
      }
    }

    .tsys-content-body {
      background: #ffffff;

      .container {
        // display: flex;
        // width: 50%;
        padding: 1rem 1rem 0 1rem;
        margin: auto;
        height: 100%;
      }

      .sleeve {
        // margin: auto;
        // display: flex;
        // justify-content: center;

        // @media screen and (max-width: 767px) {
        //     width: 100%;
        // }
      }

      .credit-card {
        margin: 20px auto 0 auto;
        display: flex;
        flex-shrink: 0;
        background: linear-gradient(
          to top left,
          #a5247f 0,
          #cb117d 33.333%,
          #a5247f 0,
          #cb117d 33.333%,
          #a5247f 100%
        );
        position: relative;
        flex-direction: column;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        color: #ffffff;
        box-shadow: 0 0 7.2px 0.8px rgba(0, 0, 0, 0.5);
        max-width: 335px;
        max-height: 164px;

        .card-number {
          display: flex;
          font-size: 12px;
          padding: 10px;
        }

        .pull-left {
          float: left;
          text-align: left;
          width: 100%;
        }

        .pull-right {
          float: right;
          width: 100%;
          text-align: right;
        }

        .card-balance {
          padding: 10px;

          .card {
            font-size: 20px;
          }

          .card-amount {
            font-size: 50px;
            font-weight: 400;
          }
        }

        // @media screen and (max-width: 767px) {
        // width: 100%;

        // .card-amount {
        //     font-size: 30px;
        // }
        // }
      }

      .activity-content-body {
        padding: 0 1rem 1.5rem 1rem;
        position: relative;
        top: -1px;
        background: #fff;

        .ReactTable {
          max-width: 1280px;
          margin: 0 auto;
          .rt-table {
            .rt-thead {
              &.-header {
                font-size: 10px;
              }

              .rt-th {
                color: #6e6e6e;
              }
            }

            .rt-tbody {
              font-size: 12px;
              color: #000;
            }
          }

          .rt-td:last-child {
            text-align: right;
          }

          .rt-th:last-child {
            text-align: right;
          }
        }
      }

      // @media screen and (max-width: 1024px) {
      //     width: 90%;
      // }
    }

    // @media screen and (max-width: 1024px) {
    //     width: 90%;
    // }
  }
}
