.language-bar {
  background-color: #6E6E6E;
  color: white;
  font-size: 14px;
  width: 100%;
  z-index: 23;
  position: fixed;
  //-box-shadow: 0px 3px 7px 2px $header-subnav-shadow-color;

  @media screen and (max-width: 1023px) {
      //position: fixed;
      left: 0px;
      top: 0px;
      right: 0px;
  }

  .content-wrapper {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      & > label {
          font-size: 14px;
          font-weight: lighter;
          color: #bebebe;

          &:after {
              content: ':';
          }
      }

      & > button {
          position: relative;
          font-size: 14px;
          line-height: 1;
          background-color: transparent;
          border: none;
          width: auto;
          height: auto;
          padding: 3px 18px 3px 3px;
          margin: 2px 0px 0px 5px;

          span {
              &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  right: 3px;
                  top: 8px;
                  width: 0px;
                  height: 0px;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid #bebebe;
              }
          }
      }

      & > ul.language-menu {
          position: absolute;
          top: 28px;
          right: 20px;
          margin: 0px;
          list-style: none;
          background-color: white;
          padding: 0px;
          z-index: 37;
          box-shadow: 0px 3px 7px 2px $header-subnav-shadow-color;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;

          & > li {
           //box-shadow:0 1px 2px 0 #818285;
           border-bottom-left-radius:4px;
           border-bottom-right-radius:4px;
           list-style:none;
           margin:0;
           padding:5px 0;
           width:100%;

              & > :hover {
                  background-color: #efefef;
                  color:#000;
              }

              & > a {
                  display: block;
                  text-decoration: none;
                  color: black;
                  font-size: 14px;
                  padding: 5px 36px 5px 12px;
              }
          }
      }
  }
}
