$merchandise-carousel-image-size: 80px !default;
$merchandise-carousel-navigation-arrow-color: $brand-primary-color;
$merchandise-carousel-navigation-arrow-thickness: 2px;
$merchandise-carousel-navigation-dot-color: #ededed;
$merchandise-carousel-navigation-dot-color-active: $brand-primary-color;
$merchandise-carousel-navigation-dot-outline-color: $brand-primary-color;

.merchandise-carousel {
  overflow: hidden;

  .merchandise-carousel-item {
    display: flex;
    flex-direction: row;
    width: 100%;

    .details {
      flex-grow: 1;
      min-height: $merchandise-carousel-image-size;
      display: flex;
      flex-direction: column;

      .details-title {
        flex-grow: 1;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        margin: 19px 0 6px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .details-points {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }
    }

    img {
      display:  block;
      width: $merchandise-carousel-image-size;
      height: $merchandise-carousel-image-size;
      margin-left: 10px;
      border: 1px solid #eee;
      border-radius: 8px;
    }
  }

  .merchandise-carousel-navigation {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 4px 0 10px 0;

    .merchandise-carousel-navigation-container {
      display: flex;
      flex-direction: row;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      width: 24px;
      height: 24px;

      &.arrow-button {
        position: relative;
        outline-color: $brand-primary-color;

        &:before {
          display: inline-block;
          content: '';
          width: 10px;
          height: 10px;
          border-bottom: $merchandise-carousel-navigation-arrow-color $merchandise-carousel-navigation-arrow-thickness solid;
          position: absolute;
          top: 7px;
        }

        &.merchandise-carousel-navigation-previous {
          &:before {
            border-left: $merchandise-carousel-navigation-arrow-color $merchandise-carousel-navigation-arrow-thickness solid;;
            transform: rotate(45deg);
            left: 9px;
          }
        }
        &.merchandise-carousel-navigation-next {
          &:before {
            border-right: $merchandise-carousel-navigation-arrow-color $merchandise-carousel-navigation-arrow-thickness solid;
            transform: rotate(-45deg);
            right: 9px;
          }
        }
      }
      &.slide-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.first {
          margin-left: 1rem;
        }

        &.last {
          margin-right: 1rem;
        }

        &:focus {
          outline: none;

          &:before {
            content: '';
            display: block;
            width: 19px;
            height: 19px;
            border: 3px solid $merchandise-carousel-navigation-dot-outline-color;
            border-radius: 16px;
            flex-shrink: 0;
            position: absolute;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: $merchandise-carousel-navigation-dot-color;
          border-radius: 50%;
        }

        &[disabled] {
          cursor: default;

          &:after {
            background-color: $merchandise-carousel-navigation-dot-color-active;
          }
        }
      }
    }
  }

  .pager {
    display: none;
  }

  &.placeholder-content {
    .merchandise-carousel-item {
      padding: 1rem 0;
    }

    .placeholder-content-image {
      width: 80px;
      height: 80px;
      margin-left: 10px;
      border: 1px solid #eee;
    }

    .merchandise-carousel-navigation {
      button.arrow-button {
        cursor: default;

        &:before {
          border-color: $placeholder-color;
        }
      }

      button.slide-button:after {
        @include placeholderPulse;
      }
    }
  }
}
