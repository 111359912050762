$alert-color-success: #3c763d !default;
$alert-color-info: #31708f !default;
$alert-color-warning: #8a6d3b !default;
$alert-color-error: #a94542 !default;
$alert-background-color-success: #dff0d8 !default;
$alert-background-color-info: #d9edf7 !default;
$alert-background-color-warning: #fcf8e3 !default;
$alert-background-color-error: #f2dede !default;
$alert-font-family: inherit !default;
$alert-font-size: 15px !default;
$alert-font-weight: normal !default;
$alert-line-height: inherit !default;
$alert-padding: 20px !default;
$alert-margin: 10px !default;
$alert-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2) !default;

$alert-close-font-family: $alert-font-family !default;
$alert-close-font-size: 20px !default;
$alert-close-font-weight: bold !default;
$alert-close-line-height: $alert-line-height !default;
$alert-close-padding: 0px 5px !default;

$alert-transition-duration: 300ms !default;
$alert-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.alerts-container {
  .alert {
    color: $alert-color-info;
    background-color: $alert-background-color-info;
    padding: $alert-padding;
    display: flex;
    flex-direction: row;
    box-shadow: $alert-box-shadow;
    margin: $alert-margin;

    .message {
      flex: 1;
      margin: auto 0px;
      font-family: $alert-font-family;
      font-size: $alert-font-size;
      font-weight: $alert-font-weight;
      line-height: $alert-line-height;
    }

    button.close {
      display: inline-block;
      box-sizing: border-box;
      height: auto;
      width: auto;
      color: $alert-color-info;
      background-color: $alert-background-color-info;
      border: none;
      border-radius: 0px;
      font-family: $alert-close-font-family;
      font-size: $alert-close-font-size;
      font-weight: $alert-close-font-weight;
      line-height: $alert-close-line-height;
      padding: $alert-close-padding;
      margin: 0px;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      box-shadow: none;
      transition: none;
    }

    p {
      position: absolute;
      text-indent: -999999px;
    }

    &.success {
      color: $alert-color-success;
      background-color: $alert-background-color-success;

      a,
      button.close {
        color: $alert-color-success;
        background-color: $alert-background-color-success;
      }
    }

    &.info {
      color: $alert-color-info;
      background-color: $alert-background-color-info;

      a,
      button.close {
        color: $alert-color-info;
        background-color: $alert-background-color-info;
      }
    }

    &.warning {
      color: $alert-color-warning;
      background-color: $alert-background-color-warning;

      a,
      button.close {
        color: $alert-color-warning;
        background-color: $alert-background-color-warning;
      }
    }

    &.error {
      color: $alert-color-error;
      background-color: $alert-background-color-error;

      a,
      button.close {
        color: $alert-color-error;
        background-color: $alert-background-color-error;
      }
    }
  }

  .alert-animate-enter {
    opacity: 0;
    transition: $alert-transition-duration $alert-transition-easing all;
  }

  .alert-animate-enter.alert-animate-enter-active {
    opacity: 1;
  }

  .alert-animate-exit {
    opacity: 1;
    transition: $alert-transition-duration $alert-transition-easing all;
  }

  .alert-animate-exit.alert-animate-exit-active {
    opacity: 0;
  }
}
