$promotion-media-aspect-ratio: percentage(9/16) !default; //9:16 = percentage(9/16);
$promotion-media-transition-duration: 300ms !default;
$promotion-media-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

$takover-promotion-content-width: 354px !default;
$takover-promotion-content-margin: 40px !default;

$takeover-promotion-task-color: $task-color !default;
$takeover-promotion-task-header-color: $task-header-color !default;
$takeover-promotion-task-expiration-color: $task-expiration-color !default;
$takeover-promotion-task-error-color: $task-error-color !default;

$takeover-promotion-task-read-more-color: $task-read-more-color !default;
$takeover-promotion-task-link-button-color: $task-link-button-color !default;

$takeover-promotion-task-label-color: $task-label-color !default;

.promotion {
  &.takeover {
    position: relative;

    & > .media {
      img {
        min-width: 100%;
        height: auto;
      }

      video {
        min-width: 100%;
        height: auto;
      }

      @media screen and (max-width: 1023px) {
        position: absolute;
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
      }

      .media-animate-enter {
        opacity: 0;
        transition: $promotion-media-transition-duration $promotion-media-transition-easing all;
      }

      .media-animate-enter.media-animate-enter-active {
        opacity: 1;
      }

      .media-animate-exit {
        opacity: 1;
        transition: $promotion-media-transition-duration $promotion-media-transition-easing all;

        & > * {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
        }
      }

      .media-animate-exit.media-animate-exit-active {
        opacity: 0;
      }
    }

    & > .content {
      max-width: 100%;
      width: 100%; //IE11
      text-align: left;
      margin: $takover-promotion-content-margin;
      position: static;
      @media screen and (max-width: 1023px) {
        position: relative;
        left: 0px;
        margin: 0px auto;
      }

      .task-container {
        background-color: transparent;

        .task {
          color: $takeover-promotion-task-color;
          background-color: transparent;

          h1 {
            color: $takeover-promotion-task-header-color;
          }

          p {
            &.expires {
              color: $takeover-promotion-task-expiration-color;
            }
          }

          form {
            text-align: left;
          }

          .form-group.submit {
            text-align: center;
          }

          .read-more {
            color: $takeover-promotion-task-read-more-color;

            span:before {
              border-right-color: $takeover-promotion-task-read-more-color;
              border-bottom-color: $takeover-promotion-task-read-more-color;
            }

            span:after {
              border-right-color: $takeover-promotion-task-read-more-color;
              border-bottom-color: $takeover-promotion-task-read-more-color;
            }
          }

          .form-group {
            label {
              color: $takeover-promotion-task-label-color;
            }

            fieldset {
              legend {
                color: $takeover-promotion-task-label-color;
              }
            }
          }
          &.video {
            .title {
              color: #fff;
            }
          }
        }

        .task-slide-container.error {
          .task-slide {
            padding-top: 0px;
          }
        }

        .error-carousel {
          & > button.previous {
            span:after {
              border-left-color: $takeover-promotion-task-error-color;
              border-bottom-color: $takeover-promotion-task-error-color;
            }
          }

          & > button.next {
            span:after {
              border-right-color: $takeover-promotion-task-error-color;
              border-bottom-color: $takeover-promotion-task-error-color;
            }
          }
        }

        .error-container {
          p {
            &.error {
              color: $takeover-promotion-task-error-color;
            }
          }
        }
      }
    }
  }
}
