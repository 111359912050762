$placeholder-color: #eee;
$placeholder-border-radius: 8px;

@mixin placeholderPulse {
  background-color: $placeholder-color;
  animation: contentPulse 1.5s infinite;
}

.placeholder-content-item {
  @include placeholderPulse;
  border-radius: $placeholder-border-radius;
}

@keyframes contentPulse {
  0% {
    background-color: $placeholder-color;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: $placeholder-color;
  }
}
