.total_points {
  position: absolute;
  top: 0;
  padding: 2px 8px;
  border-radius: 5px;
  background-color: #fff;
  color: $brand-primary-color;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .points {
    fill: $brand-primary-color;
    width: 13px;
    height: 13px;
    margin-right: 4px;
  }

  .pts {
    font-size: 10px;
    margin-top: 5px;
    margin-left: 2px;
  }
}

div.tile.large
  div.promotion.board_with_block
  div.content.is-media-background-base.is-media-background-active {
  height: 100%;
  top: 0px;
  div.task-container.carousel {
    height: 100%;
    margin-top: 22px;
  }
}

// promotion-40 has a lot of content which pushes the button off of the tile
.tile[data-item-key='promotions.p40'] .promotion > .is-media-background-base .task-container .task {
  margin-top: 36px;

  @media screen and (min-width: 768px) {
    margin: 8% auto 0 auto;
  }
  @media screen and (min-width: 1024px) {
    margin: 0 auto;
  }

  .check-complete {
    @media screen and (min-width: 500px) and (max-width: 550px) {
      margin-top: 10%;
    }
    @media screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }
}

.tile[data-item-key='promotions.p49'] .promotion > .is-media-background-base .task-container .task {
  @media screen and (min-width: 768px) {
    margin: 8% auto 0 auto;
  }
  @media screen and (min-width: 1024px) {
    margin: 0 auto;
  }
}

.tile.small .promotion > .is-media-background-base .task-container .task-slide-container .task-slide {
  margin: 12% 0 0 2%;
}

.tile.small
  .promotion.single_block
  > .is-media-background-base
  .task-container
  .task-slide-container
  .task-slide {
  margin: 0;

  .task {
    margin: 0;
    padding: 0 20px;
  }
}

.tile.small:nth-child(1 of .animate-tile-reward)  p.total_points {
  animation: flash-animation 2s;
  animation-iteration-count: 15;
  @keyframes flash-animation {
      0%, 100% { opacity: 1; }
      50% { opacity: 0; }
  }
}

.tile.large .promotion {
  &.banner .media > div {
    padding: 0;
  }
}

.tile .promotion {
  & > .media-main-active {
    transition: all 0.5s;
    // height: 320px is causing small tiles on mobile (580px wide) to become too big
    // @media screen and (max-width: 767px) {
    //     height: 320px;
    // }
    & > div {
      // @media screen and (max-width: 767px) {
      //     height: 320px;
      // }
      img {
        height: inherit;
        background-position-y: 0 !important;
      }
    }
  }

  &.banner .media > div {
    padding: 1rem 0;
  }

  .cropping-window-challenge {
    @media screen and (max-width: 500px) {
      //height: 320px;
      .dashboard-img {
        width: auto;
      }
    }
    display: flex;
    justify-content: center;
  }

  // this is some funky stuff (see javascript - search "is-media-background"
  // & > .is-media-background-inactive {
  // }
  // & > .is-media-background-base {
  // }
  // could be .is-media-background-active
  & > .is-media-background-active {
    position: absolute;
    background-color: transparent;
    max-width: unset;
  }
  & > .is-media-background-base {
    transition: all 0.5s;

    @media screen and (min-width: 300px) {
      top: 5%;
    }

    .task-container {
      background: inherit;
      .task-slide-container {
        position: static;
        .task-slide {
          margin: 7% 7% 0 7%;
          padding: 0;
          outline: 0;

          @media screen and (max-width: 629px) {
            margin: 0 2%;
          }
          @media screen and (min-width: 630px) {
            margin: 5% 5% 0 5%;
          }
          @media screen and (min-width: 1024px) {
            margin: 12% 6% 0 6%;
          }
          @media screen and (min-width: 1440px) {
            margin: 12% 0 0 0;
          }
        }
      }

      .task {
        background: inherit;
        margin-top: 80px;
        // height: 300px;

        @media screen and (max-width: 629px) {
          margin-top: 60px;
        }
        @media screen and (min-width: 630px) {
          margin: 8% auto 0 auto;
        }

        @media screen and (min-width: 768px) {
          margin: 13% auto 0 auto;
        }

        @media screen and (min-width: 1024px) {
          margin: 8% auto 0 auto;
          max-width: $content-wrapper-max-width;
        }

        @media screen and (min-width: 1100px) {
          margin: 5% auto 0 auto;
        }

        @media screen and (min-width: 1440px) {
          margin: 0 auto;
        }

        div.complete.was-p-tag {
          display: none;
        }

        .description_complete {
          color: #fff;
          margin-bottom: 1rem;
          font-size: 13px;
          @media screen and (min-width: 1024px) {
            font-size: 1.3125rem;
            width: 48%;
          }
        }

        .success-checkmark {
          position: absolute;
          top: 8%;

          @media screen and (min-width: 768px) {
            top: 28%;
            right: 5%;
            width: 200px;
            height: 200px;
          }
          @media screen and (min-width: 1024px) {
            width: 240px;
            height: 240px;
            top: 42%;
          }

          @media screen and (min-width: 1440px) {
            top: 48%;
            right: 5%;
          }
        }

        @media screen and (min-width: 768px) {
          .check-complete,
          .description_complete {
            width: 60%;
          }
        }

        // set default font-size
        .title,
        .title.complete-title,
        .check-complete .title {
          color: #fff;
          font-size: 1.3125rem;
          font-weight: 400;
          @media screen and (min-width: 768px) {
            font-size: 2.625rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.4375rem;
          }
        }
        .title-resume {
          font-size: 0.8125rem;
        }

        .title-on-resume {
          margin: 0;
          color: #fff;
          font-weight: 400;

          @media screen and (min-width: 768px) {
            font-size: 1.3125rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 1.625rem;
          }
        }

        .text,
        .description,
        .description_complete {
          color: #fff;
          margin: 0 0 1rem 0;
          font-size: 0.8125rem;
          font-weight: 200;
          margin-top: 0.5rem;
          @media screen and (min-width: 768px) {
            font-size: 1rem;
          }

          @media screen and (min-width: 1024px) {
            font-size: 1.3125rem;
          }
        }

        // .check-complete {
        //   @media screen and (min-width: 500px) and (max-width: 550px) {
        //     margin-top: 20%;
        //   }
        // }

        &.board_with_block {
          .progress-bar {
            @media screen and (min-width: 768px) {
              max-width: 345px;
            }
            @media screen and (min-width: 1024px) {
              max-width: 645px;
            }
            .status {
              margin-bottom: 0.5rem;
            }
            .react-sweet-progress-symbol {
              display: none;
            }
            .bonus-section {
              padding-top: 0.5rem;
              position: relative;
              margin-bottom: -2rem;

              @media screen and (min-width: 768px) {
                margin-bottom: 0;
              }

              p,
              .bonusPts {
                font-size: 11px;
                @media screen and (min-width: 768px) {
                  font-size: 13px;
                }
                @media screen and (min-width: 1024px) {
                  font-size: 16px;
                }
              }
              .bonusPts1,
              .bonusPts2 {
                padding-right: 2rem;
              }
              .bonusPts1::after,
              .bonusPts2::after {
                content: '';
                height: 1.25rem;
                border-right: 1px solid #ccc;
                position: absolute;
                top: 0;
                padding-left: 0.75rem;
                @media screen and (min-width: 768px) {
                  height: 1.75rem;
                }
              }

              .success-check {
                width: 12px;
                height: 12px;
                margin-right: 4px;
                fill: #cb117d;

                @media screen and (min-width: 768px) {
                  vertical-align: baseline;
                }
              }
            }
          }
          .stats {
            display: flex;
            margin-bottom: 1rem;
            @media screen and (min-width: 500px) {
              margin-bottom: 0.5rem;
            }
            @media screen and (max-width: 630px) {
              flex-direction: column;
            }
          }
          p.status {
            display: inline-block;
            color: #fff;
            text-align: left;
            margin: 0;
            margin-right: 1.5rem;

            @media screen and (max-width: 630px) {
              font-size: 1.5rem;
            }

            @media screen and (min-width: 1024px) {
              font-size: 3.5625rem;
            }

            @media screen and (min-width: 1440px) {
              font-size: 5.625rem;
              margin-right: 5.625rem;
            }

            .of,
            .total {
              font-weight: 200;
            }

            .activities {
              color: #fff;
              font-size: 1.125rem;
              font-weight: 200;
              margin: 0 0 0 0.2rem;

              @media screen and (min-width: 768px) {
                font-size: 1.725rem;
              }

              @media screen and (min-width: 1024px) {
                font-size: 2.125rem;
              }
            }
          }
        }
      }
    }
    &::after {
      display: none;
    }
  }

  &.banner {
    text-align: center;
  }

  // Without the following style the survey under archived powerups is screwed up
  &.single_block.banner {
    text-align: left;
  }
}

.tile.large .form-group.submit .button span {
  padding: 0;
  &::before,
  &::after {
    display: none;
  }
}

.tile {
  background-color: transparent;
  .carousel {
    .slide-container {
      padding: 1rem 0;
      .slide {
        background-color: transparent;
      }
    }
    .pager,
    button.prev,
    button.next {
      display: none;
    }
  }
}

.tile.small {
  @media screen and (min-width: 500px) {
    vertical-align: top;
    display: inline-block;
  }
  .promotion {
    & > .media-main-base {
      & > div img {
        border-radius: 10px;
        // width: 92%;
        box-shadow: 0px 0px 7.2px 0.8px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.meter-component.radial-meter {
  .percent {
    font-size: 0.35px;
  }
  &.challenge-progress {
    position: absolute;
    right: 0;
    top: 8rem;
    width: 24rem;

    @media screen and (min-width: 300px) {
      width: 6rem;
      top: 7.875rem;
      right: 3.125rem;
    }

    @media screen and (min-width: 480px) {
      width: 10rem;
      top: 5rem;
    }

    @media screen and (min-width: 560px) {
      width: 14rem;
      top: 2rem;
    }

    @media screen and (min-width: 630px) {
      width: 12rem;
      top: 2rem;
    }

    @media screen and (min-width: 667px) {
      width: 14rem;
    }

    @media screen and (min-width: 712px) {
      width: 18rem;
      top: 1rem
    }

    @media screen and (min-width: 768px) {
      top: 3rem;
    }

    @media screen and (min-width: 812px) {
      width: 20rem;
    }

    @media screen and (min-width: 896px) {
      width: 22rem;
    }

    @media screen and (min-width: 1024px) {
      top: 5rem;
      right: 2rem;
    }

    @media screen and (min-width: 1440px) {
      right: 4rem;
      width: 30rem;
    }

    .meter-bg {
      fill: none;
      stroke-width: 0.14615px;
      stroke: #999;
    }
    .bar {
      stroke-width: 0.13615px;
      stroke: #fff;
    }
  }
}
