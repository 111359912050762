$focus-color: #1b77cb !default;

$calendar-header-color: black !default;
$calendar-header-background-color: white !default;
$calendar-header-font-family: inherit !default;
$calendar-header-font-size: 14px !default;
$calendar-header-font-weight: normal !default;
$calendar-header-line-height: inherit !default;
$calendar-header-padding: 4px 0px 4px 0px !default;
$calendar-header-margin: 0px 0px 4px 0px !default;

$calendar-arrow-color: $calendar-header-color !default;
$calendar-arrow-width: 20px !default;
$calendar-arrow-height: 20px !default;
$calendar-arrow-margin: 0px 4px !default;

$calendar-color: black !default;
$calendar-background-color: white !default;
$calendar-font-family: inherit !default;
$calendar-font-size: 14px !default;
$calendar-font-weight: normal !default;
$calendar-line-height: inherit !default;

$calendar-weekend-color: gray !default;
$calendar-weekend-font-family: $calendar-font-family !default;
$calendar-weekend-font-size: $calendar-font-size !default;
$calendar-weekend-font-weight: $calendar-font-weight !default;
$calendar-weekend-line-height: $calendar-line-height !default;

$calendar-today-color: #000 !default;
$calendar-today-background-color: $calendar-background-color !default;
$calendar-today-font-family: $calendar-font-family !default;
$calendar-today-font-size: $calendar-font-size !default;
$calendar-today-font-weight: $calendar-font-weight !default;
$calendar-today-line-height: $calendar-line-height !default;

$calendar-overflow-color: #cccccc !default;
$calendar-overflow-font-family: $calendar-font-family !default;
$calendar-overflow-font-size: $calendar-font-size !default;
$calendar-overflow-font-weight: $calendar-font-weight !default;
$calendar-overflow-line-height: $calendar-line-height !default;

$calendar-range-color: black !default;
$calendar-range-background-color: lightgray !default;
$calendar-range-font-family: $calendar-font-family !default;
$calendar-range-font-size: $calendar-font-size !default;
$calendar-range-font-weight: $calendar-font-weight !default;
$calendar-range-line-height: $calendar-line-height !default;

$calendar-range-cap-color: white !default;
$calendar-range-cap-background-color: gray !default;

$calendar-selected-color: #000 !default;
$calendar-selected-background-color: #e9e9e9 !default;
$calendar-selected-font-family: $calendar-font-family !default;
$calendar-selected-font-size: $calendar-font-size !default;
$calendar-selected-font-weight: $calendar-font-weight !default;
$calendar-selected-line-height: $calendar-line-height !default;

$calendar-hover-color: $calendar-color !default;
$calendar-hover-background-color: #cccccc !default;
$calendar-hover-font-family: $calendar-font-family !default;
$calendar-hover-font-size: $calendar-font-size !default;
$calendar-hover-font-weight: $calendar-font-weight !default;
$calendar-hover-line-height: $calendar-line-height !default;

$calendar-day-border-style: none !default;
$calendar-day-border-width: 0px !default;
$calendar-day-border-color: transparent !default;
$calendar-day-border-radius: 15px !default;
$calendar-day-height: 30px !default;
$calendar-day-width: 30px !default;
$calendar-day-padding: 8px !default;

$calendar-month-border-style: none !default;
$calendar-month-border-width: 0px !default;
$calendar-month-border-color: transparent !default;
$calendar-month-border-radius: 21px !default;
$calendar-month-height: 42px !default;
$calendar-month-width: 42px !default;
$calendar-month-padding: 2px !default;

$calendar-year-border-style: none !default;
$calendar-year-border-width: 0px !default;
$calendar-year-border-color: transparent !default;
$calendar-year-border-radius: 21px !default;
$calendar-year-height: 42px !default;
$calendar-year-width: 42px !default;
$calendar-year-padding: 2px !default;

$calendar-day-of-week-color: gray !default;
$calendar-day-of-week-font-family: $calendar-font-family !default;
$calendar-day-of-week-font-size: ($calendar-font-size * (12/14)) !default;
$calendar-day-of-week-font-weight: bold !default;
$calendar-day-of-week-line-height: $calendar-line-height !default;

.calendar-header {
  display: flex;
  color: $calendar-header-color;
  background-color: $calendar-header-background-color;
  padding: $calendar-header-padding;
  margin: $calendar-header-margin;

  & > button.previous {
    display: inline-block;
    width: $calendar-arrow-width;
    height: $calendar-arrow-height;
    margin: $calendar-arrow-margin;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 0px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    span:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-left: $calendar-arrow-color 2px solid;
      border-bottom: $calendar-arrow-color 2px solid;
      transform: rotate(45deg);
      position: absolute;
      left: 8px;
      top: 5px;
    }
  }

  & > button.next:not([disabled]) {
    border: none !important;
    background: transparent !important;
    cursor: pointer !important;
  }

  & > button.next {
    display: inline-block !important;
    width: $calendar-arrow-width !important;
    height: $calendar-arrow-height !important;
    margin: $calendar-arrow-margin !important;
    padding: 0px !important;
    box-sizing: border-box;
    border-radius: 0px;
    text-decoration: none;
    text-align: center;

    opacity: 1 !important;
    position: static !important;
    top: auto !important;
    right: auto !important;

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    span:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-right: $calendar-arrow-color 2px solid;
      border-bottom: $calendar-arrow-color 2px solid;
      transform: rotate(-45deg);
      position: absolute;
      left: 3px;
      top: 5px;
    }
  }

  button.text {
    flex: 0 1;
    display: inline-block;
    border: 2px solid $brand-primary-color;
    background: transparent;
    cursor: pointer;
    font-family: $calendar-header-font-family;
    font-size: $calendar-header-font-size !important;
    font-weight: $calendar-header-font-weight;
    line-height: $calendar-header-line-height;
    padding: 0px;
    margin: 0px !important;
    box-sizing: border-box;
    height: auto;
    border-radius: 4px;
    color: $white !important;
    background-color: $brand-primary-color;
    text-decoration: none;
    text-align: center;
    
    &:hover {
      background-color: $button-background-color-hover;
      border: $button-border-hover;
    }
  }
}

.date-view {
  box-sizing: border-box;
  background-color: $calendar-background-color;

  & > div.week {
    box-sizing: border-box;
    display: block;

    & > div.day-of-week {
      box-sizing: border-box;
      display: inline-block;
      color: $calendar-day-of-week-color;
      width: 14.2857%;
      text-align: center;
      padding: $calendar-day-padding;
      font-family: $calendar-day-of-week-font-family;
      font-size: $calendar-day-of-week-font-size;
      font-weight: $calendar-day-of-week-font-weight;
      line-height: $calendar-day-of-week-line-height;
    }

    & > div.day {
      box-sizing: border-box;
      display: inline-block;
      width: 14.2857%;
      text-align: center;
      padding: $calendar-day-padding;
      color: $calendar-color;
      font-family: $calendar-font-family;
      font-size: $calendar-font-size;
      font-weight: $calendar-font-weight;
      line-height: $calendar-line-height;

      &.sun,
      &.sat {
        color: $calendar-weekend-color;
        font-family: $calendar-weekend-font-family;
        font-size: $calendar-weekend-font-size;
        font-weight: $calendar-weekend-font-weight;
        line-height: $calendar-weekend-line-height;
      }

      &.not-in-month,
      &.not-within-range {
        color: $calendar-overflow-color;
        font-family: $calendar-overflow-font-family;
        font-size: $calendar-overflow-font-size;
        font-weight: $calendar-overflow-font-weight;
        line-height: $calendar-overflow-line-height;
      }

      &.not-within-range {
        opacity: 0;
        cursor: default !important;
      }

      & > span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $calendar-day-width;
        height: $calendar-day-height;
        border-style: $calendar-day-border-style;
        border-width: $calendar-day-border-width;
        border-color: $calendar-day-border-color;
        border-radius: $calendar-day-border-radius;
        margin: auto;
      }

      &.today {
        & > span {
          color: $calendar-today-color;
          background-color: $calendar-today-background-color;
          font-family: $calendar-today-font-family;
          font-size: $calendar-today-font-size;
          font-weight: $calendar-today-font-weight;
          line-height: $calendar-today-line-height;
        }
      }

      & > span {
        &:hover {
          color: $calendar-hover-color;
          background-color: $calendar-hover-background-color;
          font-family: $calendar-hover-font-family;
          font-size: $calendar-hover-font-size;
          font-weight: $calendar-hover-font-weight;
          line-height: $calendar-hover-line-height;
        }
      }

      &.range {
        & > span {
          color: $calendar-color;
          background-color: $calendar-range-background-color;
          font-family: $calendar-range-font-family;
          font-size: $calendar-range-font-size;
          font-weight: $calendar-range-font-weight;
          line-height: $calendar-range-line-height;
          border-radius: 0px;
        }
      }

      &.range-from {
        & > span {
          color: $calendar-range-cap-color;
          background-color: $calendar-range-cap-background-color;
          border-top-left-radius: $calendar-day-border-radius;
          border-bottom-left-radius: $calendar-day-border-radius;
        }
      }

      &.range-to {
        & > span {
          color: $calendar-range-cap-color;
          background-color: $calendar-range-cap-background-color;
          border-top-right-radius: $calendar-day-border-radius;
          border-bottom-right-radius: $calendar-day-border-radius;
        }
      }

      &.selected {
        & > span {
          color: $calendar-selected-color;
          background-color: $calendar-selected-background-color;
          font-family: $calendar-selected-font-family;
          font-size: $calendar-selected-font-size;
          font-weight: $calendar-selected-font-weight;
          line-height: $calendar-selected-line-height;
        }
      }

      &.focus {
        & > span {
          box-shadow: 0 0 3px 1px $focus-color;
        }
      }
    }
  }
}

.month-view {
  box-sizing: border-box;
  background-color: $calendar-background-color;

  & > div.quarter {
    box-sizing: border-box;
    display: block;

    & > div.month {
      box-sizing: border-box;
      display: inline-block;
      width: 33.3333%;
      text-align: center;
      padding: $calendar-month-padding;
      color: $calendar-color;
      font-family: $calendar-font-family;
      font-size: $calendar-font-size;
      font-weight: $calendar-font-weight;
      line-height: $calendar-line-height;

      &.not-within-range {
        > span {
          opacity: 0;
          cursor: default !important;
        }
      }

      & > span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $calendar-month-width;
        height: $calendar-month-height;
        border-style: $calendar-month-border-style;
        border-width: $calendar-month-border-width;
        border-color: $calendar-month-border-color;
        border-radius: $calendar-month-border-radius;
        margin: auto;
      }

      &.today {
        & > span {
          color: $calendar-today-color;
          background-color: $calendar-today-background-color;
          font-family: $calendar-today-font-family;
          font-size: $calendar-today-font-size;
          font-weight: $calendar-today-font-weight;
          line-height: $calendar-today-line-height;
        }
      }

      & > span {
        &:hover {
          color: $calendar-hover-color;
          background-color: $calendar-hover-background-color;
          font-family: $calendar-hover-font-family;
          font-size: $calendar-hover-font-size;
          font-weight: $calendar-hover-font-weight;
          line-height: $calendar-hover-line-height;
          cursor: pointer;
        }
      }

      &.selected {
        & > span {
          color: $calendar-selected-color;
          background-color: $calendar-selected-background-color;
          font-family: $calendar-selected-font-family;
          font-size: $calendar-selected-font-size;
          font-weight: $calendar-selected-font-weight;
          line-height: $calendar-selected-line-height;
        }
      }

      &.focus {
        & > span {
          box-shadow: 0 0 3px 1px $focus-color;
        }
      }
    }
  }
}

.year-view {
  box-sizing: border-box;
  background-color: $calendar-background-color;

  & > div.decade {
    box-sizing: border-box;
    display: block;

    & > div.year {
      box-sizing: border-box;
      display: inline-block;
      width: 33.3333%;
      text-align: center;
      padding: $calendar-year-padding;
      color: $calendar-color;
      font-family: $calendar-font-family;
      font-size: $calendar-font-size;
      font-weight: $calendar-font-weight;
      line-height: $calendar-line-height;

      &.not-within-range {
        > span {
          opacity: 0;
          cursor: default !important;
        }
      }

      & > span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $calendar-year-width;
        height: $calendar-year-height;
        border-style: $calendar-year-border-style;
        border-width: $calendar-year-border-width;
        border-color: $calendar-year-border-color;
        border-radius: $calendar-year-border-radius;
        margin: auto;
      }

      &.not-in-decade {
        color: $calendar-overflow-color;
        font-family: $calendar-overflow-font-family;
        font-size: $calendar-overflow-font-size;
        font-weight: $calendar-overflow-font-weight;
        line-height: $calendar-overflow-line-height;
      }

      &.today {
        & > span {
          color: $calendar-today-color;
          background-color: $calendar-today-background-color;
          font-family: $calendar-today-font-family;
          font-size: $calendar-today-font-size;
          font-weight: $calendar-today-font-weight;
          line-height: $calendar-today-line-height;
        }
      }

      & > span {
        &:hover {
          color: $calendar-hover-color;
          background-color: $calendar-hover-background-color;
          font-family: $calendar-hover-font-family;
          font-size: $calendar-hover-font-size;
          font-weight: $calendar-hover-font-weight;
          line-height: $calendar-hover-line-height;
          cursor: pointer;
        }
      }

      &.selected {
        & > span {
          color: $calendar-selected-color;
          background-color: $calendar-selected-background-color;
          font-family: $calendar-selected-font-family;
          font-size: $calendar-selected-font-size;
          font-weight: $calendar-selected-font-weight;
          line-height: $calendar-selected-line-height;
        }
      }

      &.focus {
        & > span {
          box-shadow: 0 0 3px 1px $focus-color;
        }
      }
    }
  }
}

$calendar-header-font-size-mobile: 16px !default;
$calendar-header-padding-mobile: 6px 0px 6px 0px !default;
$calendar-header-margin-mobile: 0px 0px 6px 0px !default;

$calendar-arrow-width-mobile: 30px !default;
$calendar-arrow-height-mobile: 30px !default;
$calendar-arrow-margin-mobile: 0px 6px !default;

$calendar-font-size-mobile: 16px !default;

$calendar-weekend-font-size-mobile: $calendar-font-size-mobile !default;

$calendar-today-font-size-mobile: $calendar-font-size-mobile !default;

$calendar-overflow-font-size-mobile: $calendar-font-size-mobile !default;

$calendar-selected-font-size-mobile: $calendar-font-size-mobile !default;

$calendar-hover-font-size-mobile: $calendar-font-size-mobile !default;

$calendar-day-border-width-mobile: 0px !default;
$calendar-day-border-radius-mobile: 18px !default;
$calendar-day-height-mobile: 30px !default;
$calendar-day-width-mobile: 30px !default;
$calendar-day-padding-mobile: 8px !default;

$calendar-month-border-width-mobile: 0px !default;
$calendar-month-border-radius-mobile: 26px !default;
$calendar-month-height-mobile: 42px !default;
$calendar-month-width-mobile: 42px !default;
$calendar-month-padding-mobile: 2px !default;

$calendar-year-border-width-mobile: 0px !default;
$calendar-year-border-radius-mobile: 26px !default;
$calendar-year-height-mobile: 42px !default;
$calendar-year-width-mobile: 42px !default;
$calendar-year-padding-mobile: 2px !default;

$calendar-day-of-week-font-size-mobile: 13px !default;

@media screen and (max-width: 1023px) {
  .calendar-header {
    padding: $calendar-header-padding-mobile;
    margin: $calendar-header-margin-mobile;

    & > button.previous {
      width: $calendar-arrow-width-mobile;
      height: $calendar-arrow-height-mobile;
      margin: $calendar-arrow-margin-mobile;

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }

      span:after {
        width: 12px;
        height: 12px;
        border-left: $calendar-arrow-color 3px solid;
        border-bottom: $calendar-arrow-color 3px solid;
        left: 11px;
        top: 7px;
      }
    }

    & > button.next {
      width: $calendar-arrow-width-mobile;
      height: $calendar-arrow-height-mobile;
      margin: $calendar-arrow-margin-mobile;

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }

      span:after {
        width: 12px;
        height: 12px;
        border-right: $calendar-arrow-color 3px solid;
        border-bottom: $calendar-arrow-color 3px solid;
        left: 4px;
        top: 7px;
      }
    }

    button.text {
      font-size: $calendar-header-font-size-mobile;
    }
  }

  .date-view {
    & > div.week {
      & > div.day-of-week {
        padding: $calendar-day-padding-mobile;
        font-size: $calendar-day-of-week-font-size-mobile;
      }

      & > div.day {
        padding: $calendar-day-padding-mobile;
        font-size: $calendar-font-size-mobile;

        &.sun,
        &.sat {
          font-size: $calendar-weekend-font-size-mobile;
        }

        &.not-in-month {
          font-size: $calendar-overflow-font-size-mobile;
        }

        & > span {
          width: $calendar-day-width-mobile;
          height: $calendar-day-height-mobile;
          border-width: $calendar-day-border-width-mobile;
          border-radius: $calendar-day-border-radius-mobile;
        }

        &.today {
          & > span {
            font-size: $calendar-today-font-size-mobile;
          }
        }

        & > span {
          &:hover {
            font-size: $calendar-hover-font-size-mobile;
          }
        }

        &.selected {
          & > span {
            font-size: $calendar-selected-font-size-mobile;
          }
        }
      }
    }
  }

  .month-view {
    & > div.quarter {
      & > div.month {
        padding: $calendar-month-padding-mobile;
        font-size: $calendar-font-size-mobile;

        & > span {
          width: $calendar-month-width-mobile;
          height: $calendar-month-height-mobile;
          border-width: $calendar-month-border-width-mobile;
          border-radius: $calendar-month-border-radius-mobile;
        }

        &.today {
          & > span {
            font-size: $calendar-today-font-size-mobile;
          }
        }

        & > span {
          &:hover {
            font-size: $calendar-hover-font-size-mobile;
          }
        }

        &.selected {
          & > span {
            font-size: $calendar-selected-font-size-mobile;
          }
        }
      }
    }
  }

  .year-view {
    & > div.decade {
      & > div.year {
        padding: $calendar-year-padding-mobile;
        font-size: $calendar-font-size-mobile;

        & > span {
          width: $calendar-year-width-mobile;
          height: $calendar-year-height-mobile;
          border-width: $calendar-year-border-width-mobile;
          border-radius: $calendar-year-border-radius-mobile;
        }

        &.not-in-decade {
          font-size: $calendar-overflow-font-size-mobile;
        }

        &.today {
          & > span {
            font-size: $calendar-today-font-size-mobile;
          }
        }

        & > span {
          &:hover {
            font-size: $calendar-hover-font-size-mobile;
          }
        }

        &.selected {
          & > span {
            font-size: $calendar-selected-font-size-mobile;
          }
        }
      }
    }
  }
}
